define('ember-file-upload/helpers/file-queue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Ember.Helper.extend({

    fileQueue: service(),

    compute: function compute(_, hash) {
      var queues = get(this, 'fileQueue');
      var queueName = hash['name'];
      if (queueName) {
        var queue = queues.find(queueName) || queues.create(queueName);
        return queue;
      }

      return queues;
    }
  });
});