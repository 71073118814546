define('ember-file-upload/components/file-dropzone/component', ['exports', 'ember-file-upload/components/file-dropzone/template', 'ember-file-upload/system/data-transfer', 'ember-file-upload/system/uuid', 'ember-file-upload/system/drag-listener'], function (exports, _template, _dataTransfer, _uuid, _dragListener) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var $ = Ember.$,
      _get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  var bind = Ember.run.bind;
  var service = Ember.inject.service;


  var DATA_TRANSFER = 'DATA_TRANSFER' + _uuid.default.short();

  var supported = function () {
    return typeof window !== 'undefined' && window.document && 'draggable' in document.createElement('span');
  }();

  var dragListener = new _dragListener.default();

  /**
    @class file-dropzone
    @type Ember.Component
   */
  exports.default = Ember.Component.extend({

    layout: _template.default,

    /**
      The name of the queue that files should be
      added to when they get dropped.
       @attribute name
      @type string
     */
    name: null,

    supported: supported,

    /**
      `ondragenter` is called when a file has entered
      the dropzone.
       @attribute ondragenter
      @type function
     */
    ondragenter: null,

    /**
      `ondragleave` is called when a file has left
      the dropzone.
       @attribute ondragleave
      @type function
     */
    ondragleave: null,

    /**
      `ondrop` is called when a file has been dropped.
       @attribute ondrop
      @type function
     */
    ondrop: null,

    fileQueue: service(),

    /**
      Whether users can upload content
      from websites by dragging images from
      another webpage and dropping it into
      your app. The default is `false` to
      prevent cross-site scripting issues.
       @attribute allowUploadsFromWebsites
      @type boolean
      @default false
     */
    allowUploadsFromWebsites: false,

    /**
      This is the type of cursor that should
      be shown when a drag event happens.
       Corresponds to `dropEffect`.
       This is one of the following:
       - `copy`
      - `move`
      - `link`
       @attribute cursor
      @type string
      @default null
     */
    cursor: null,

    queue: computed('name', {
      get: function get() {
        var queueName = _get(this, 'name');
        var queues = _get(this, 'fileQueue');
        return queues.find(queueName) || queues.create(queueName);
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super();

      dragListener.addEventListeners('#' + _get(this, 'elementId'), {
        dragenter: bind(this, 'didEnterDropzone'),
        dragleave: bind(this, 'didLeaveDropzone'),
        dragover: bind(this, 'didDragOver'),
        drop: bind(this, 'didDrop')
      });
    },
    willDestroyElement: function willDestroyElement() {
      dragListener.removeEventListeners('#' + _get(this, 'elementId'));
    },
    isAllowed: function isAllowed() {
      return _get(this[DATA_TRANSFER], 'source') === 'os' || _get(this, 'allowUploadsFromWebsites');
    },
    didEnterDropzone: function didEnterDropzone(evt) {
      var dataTransfer = _dataTransfer.default.create({
        queue: _get(this, 'queue'),
        source: evt.source,
        dataTransfer: evt.dataTransfer
      });
      this[DATA_TRANSFER] = dataTransfer;

      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = _get(this, 'cursor');
        set(this, 'active', true);
        set(this, 'valid', _get(dataTransfer, 'valid'));

        if (this.ondragenter) {
          this.ondragenter(dataTransfer);
        }
      }
    },
    didLeaveDropzone: function didLeaveDropzone(evt) {
      set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      if (this.isAllowed()) {
        if (evt.dataTransfer) {
          evt.dataTransfer.dropEffect = _get(this, 'cursor');
        }
        if (this.ondragleave) {
          this.ondragleave(this[DATA_TRANSFER]);
          this[DATA_TRANSFER] = null;
        }

        set(this, 'active', false);
      }
    },
    didDragOver: function didDragOver(evt) {
      set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = _get(this, 'cursor');
      }
    },
    didDrop: function didDrop(evt) {
      var _this = this;

      set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);

      if (!this.isAllowed()) {
        evt.dataTransfer.dropEffect = _get(this, 'cursor');
        this[DATA_TRANSFER] = null;
        return;
      }

      // Testing support for dragging and dropping images
      // from other browser windows
      var url = void 0;

      var html = this[DATA_TRANSFER].getData('text/html');
      if (html) {
        var img = $(html)[1];
        if (img.tagName === 'IMG') {
          url = img.src;
        }
      }

      if (url == null) {
        url = this[DATA_TRANSFER].getData('text/uri-list');
      }

      if (url) {
        var image = new Image();

        var _url$split$slice = url.split('/').slice(-1),
            _url$split$slice2 = _slicedToArray(_url$split$slice, 1),
            filename = _url$split$slice2[0];

        image.crossOrigin = 'anonymous';
        image.onload = function () {
          var canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;

          var ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);

          if (canvas.toBlob) {
            canvas.toBlob(function (blob) {
              var _get$_addFiles = _get(_this, 'queue')._addFiles([blob], 'web'),
                  _get$_addFiles2 = _slicedToArray(_get$_addFiles, 1),
                  file = _get$_addFiles2[0];

              set(file, 'name', filename);
            });
          } else {
            var binStr = atob(canvas.toDataURL().split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            var blob = new Blob([arr], { type: 'image/png' });
            blob.name = filename;

            var _get$_addFiles3 = _get(_this, 'queue')._addFiles([blob], 'web'),
                _get$_addFiles4 = _slicedToArray(_get$_addFiles3, 1),
                file = _get$_addFiles4[0];

            set(file, 'name', filename);
          }
        };
        /* eslint-disable no-console */
        image.onerror = function (e) {
          console.log(e);
        };
        /* eslint-enable no-console */
        image.src = url;
      }

      if (this.ondrop) {
        this.ondrop(this[DATA_TRANSFER]);
      }

      // Add file(s) to upload queue.
      set(this, 'active', false);
      _get(this, 'queue')._addFiles(_get(this[DATA_TRANSFER], 'files'), 'drag-and-drop');
      this[DATA_TRANSFER] = null;
    }
  });
});