define("ember-dialog/mixins/context", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.makeArgsArray = makeArgsArray;
  exports.execAction = execAction;


  /**
   * @module ember-dialog/mixins/context
   * @augments Ember.Mixin
   */

  function makeArgsArray(args, obj) {
    var argsArray = Array.prototype.slice.apply(args);
    argsArray.unshift(obj);
    return argsArray;
  }

  function execAction(actionName, args) {
    var context = Ember.get(this, "contextObject");
    // @todo: Magic concatenation
    actionName = Ember.get(this, actionName + "Handler");
    args = makeArgsArray(args, this);
    if (context && context.actions && context.actions[actionName]) {
      if (Ember.typeOf(context.send) === "function") {
        args.unshift(actionName);
        context.send.apply(context, args);
      } else {
        context.actions[actionName].apply(context, args);
      }
    } else {
      this[actionName].apply(this);
    }
  }

  exports.default = Ember.Mixin.create({

    /**
     * @property {Function} accept
     * @property {Function} decline
     */
    actions: {
      accept: function accept() {
        execAction.call(this, "accept", arguments);
      },
      decline: function decline() {
        execAction.call(this, "decline", arguments);
      }
    }

  });
});