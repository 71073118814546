define('ember-pdf-js/services/pdf-js', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner,
      Service = Ember.Service;
  exports.default = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var appConfig = getOwner(this).resolveRegistration('config:environment');
      var addonConfig = appConfig.emberPdfJs;

      this.PDFJS = PDFJS;
      this.PDFJS.workerSrc = addonConfig.workerSrc;
    }
  });
});