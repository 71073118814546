define('ember-dialog/configuration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = {
    load: function load(config) {
      var wrappedConfig = Ember.Object.create(config);
      for (var property in wrappedConfig) {
        if (wrappedConfig.hasOwnProperty(property)) {
          var value = get(wrappedConfig, property);
          if (Ember.typeOf(get(this, property)) === 'undefined') {
            set(this, property, value);
          }
        }
      }
    }
  };
});