define("ember-autoresize/ext/text-area", ["ember-autoresize/mixins/autoresize"], function (_autoresize) {
  "use strict";

  var _get = Ember.get;
  var isNone = Ember.isNone;

  /**
    @element text-area
   */
  Ember.TextArea.reopen(_autoresize.default, {

    /**
      By default, textareas only resize
      their height.
       @attribute shouldResizeHeight
      @type Boolean
     */
    shouldResizeHeight: true,

    /**
      Whitespace should be treated as significant
      for text areas.
       @attribute significantWhitespace
      @default true
      @type Boolean
     */
    significantWhitespace: true,

    /**
      Optimistically resize the height
      of the textarea so when users reach
      the end of a line, they will be
      presented with space to begin typing.
       @attribute autoResizeText
      @type String
     */
    autoResizeText: Ember.computed('value', {
      get: function get() {
        var value = _get(this, 'value');
        if (isNone(value)) {
          value = '';
        }
        return value + '@';
      }
    })

  });
});