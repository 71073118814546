define('ember-collapsible-panel/components/cp-panel-body/component', ['exports', 'ember-collapsible-panel/components/cp-panel-body/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['cp-Panel-body'],
    classNameBindings: ['isOpen:cp-is-open']

  });
});