define('ember-sortable/system/scroll-parent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function ($element) {
    var position = $element.css('position');
    var excludeStaticParent = position === 'absolute';
    var $scrollParent = $element.parents().filter(function () {
      var $parent = Ember.$(this);
      if (excludeStaticParent && $parent.css('position') === 'static') {
        return false;
      }

      var _$parent$css = $parent.css(['overflow', 'overflowX', 'overflowY']),
          overflow = _$parent$css.overflow,
          overflowX = _$parent$css.overflowX,
          overflowY = _$parent$css.overflowY;

      return (/(auto|scroll)/.test(overflow + overflowX + overflowY)
      );
    }).eq(0);

    if ($scrollParent.length === 0 || $scrollParent[0] === document.body) {
      $scrollParent = Ember.$(document);
    }
    return position === 'fixed' || $scrollParent;
  };
});