define('ember-inputmask/components/input-mask', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    mask: '',

    showMaskOnFocus: true,
    showMaskOnHover: true,
    rightAlign: false,
    clearIncomplete: false,
    greedyMask: false,
    debounce: 0,

    // Make this addon to initially set pattern attribute for working out of the box with
    // Ember addon like ember-cli-html5-validation
    pattern: null,

    value: 'value',

    options: Ember.computed(function () {
      return {};
    }),

    // Initialize the mask by forcing a
    // call to the updateMask function
    didInsertElement: function didInsertElement() {
      this.propertyDidChange('mask');
    },

    // Remove the mask from the input
    teardownMask: Ember.on('willDestroyElement', function () {
      if (this.element.inputmask) {
        this.element.inputmask.remove();
      }
    }),

    setMask: function setMask() {
      Inputmask.extendDefinitions({
        '2': {
          'validator': '[2-9]'
        }
      });

      if (!this.element) {
        return;
      }

      var mask = this.get('mask'),
          options = this.get('options');

      if (this.element.inputmask) {
        this.element.inputmask.remove();
      }

      var inputmask = new Inputmask(mask, options);
      inputmask.mask(this.element);

      // Initialize the unmasked value if it exists
      if (Ember.isPresent(this.get('unmaskedValue'))) {
        this.element.value = this.get('unmaskedValue');
      }

      // If the mask has changed, we need to refocus the input to show the
      // proper mask preview. Since the caret is not positioned by the focus
      // even, but the click event, we need to trigger a click as well.
      if (this.element === document.activeElement) {
        this.element.blur();
        this.element.focus();
        this.element.click();
      }
    },

    // Update the mask whenever the mask itself changes or one of the options changes.
    // This observer is meant to be extensible so that other fields can add options
    // (See `decimal-input`), which is why the actual setting of the mask is handled
    // in another function.
    updateMask: function updateMask() {
      if (this.get('mask').toLowerCase() === 'regex') {
        // Note: I like pattern better, but I'll leave regex in as an option
        // as well since that's what the plugin defines on the options hash
        this.set('options.regex', this.get('pattern') || this.get('regex'));
        this.set('options.mask', '');
      }

      this.setProperties({
        'options.placeholder': this.get('maskPlaceholder'),
        'options.showMaskOnFocus': this.get('showMaskOnFocus'),
        'options.showMaskOnHover': this.get('showMaskOnHover'),
        'options.rightAlign': this.get('rightAlign'),
        'options.clearIncomplete': this.get('clearIncomplete'),
        'options.greedy': this.get('greedyMask')
      });

      this.setMask();
    },

    _maskShouldChange: Ember.observer('mask', 'maskPlaceholder', 'showMaskOnFocus', 'showMaskOnHover', 'rightAlign', 'clearIncomplete', 'greedyMask', 'pattern', 'regex', function () {
      Ember.run.once(this, 'updateMask');
    }),

    updateVar: function updateVar() {
      if (!this.element || !this.element.inputmask) {
        return;
      }
      if (this.element.inputmask.unmaskedvalue() !== this.get('unmaskedValue')) {
        this.element.value = this.get('unmaskedValue');
      }
    },

    // Unmask the value of the field and set the property.
    setUnmaskedValue: Ember.observer('value', function () {
      if (this.element && this.element.inputmask) {
        this.set('unmaskedValue', this.element.inputmask.unmaskedvalue());
      }
    }),

    // When the unmaskedValue changes, set the value.
    setValue: Ember.observer('unmaskedValue', function () {
      var debounceTime = this.get('debounce');
      if (debounceTime) {
        Ember.run.debounce(this, this.updateVar, Ember.run.debounce);
      } else {
        this.updateVar();
      }
    })
  });
});