define('ember-query-params-reset/mixins/query-params-reset-route', ['exports', 'ember-metal/mixin', 'ember-query-params-reset/utils/reset-query-params'], function (exports, _mixin, _resetQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mixin.default.create({
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        (0, _resetQueryParams.default)(this);
      }

      this._super.apply(this, arguments);
    }
  });
});