define('ember-collapsible-panel/components/cp-panel-toggle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'a',
    classNames: ['cp-Panel-toggle'],
    classNameBindings: ['isOpen:cp-is-open'],

    // So taps register in iOS
    attributeBindings: ['href', 'isOpen:aria-expanded'],
    href: '#',

    click: function click(e) {
      e.preventDefault();
      this.get('on-click')();
    }
  });
});