define('ember-validated-form/components/validated-form', ['exports', 'ember', 'ember-validated-form/templates/components/validated-form'], function (exports, _ember, _emberValidatedFormTemplatesComponentsValidatedForm) {

  function runTaskOrAction(taskOrAction, model) {
    return taskOrAction.perform ? taskOrAction.perform(model) : taskOrAction(model);
  }

  exports['default'] = _ember['default'].Component.extend({
    submitted: false,

    layout: _emberValidatedFormTemplatesComponentsValidatedForm['default'],

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('validateOnInit') !== false) {
        this.set('validateOnInit', true);
      }
      if (this.get('model') && this.get('model').validate && this.get('validateOnInit')) {
        this.get('model').validate();
      }

      var owner = _ember['default'].getOwner(this);
      var factory = owner.factoryFor ? owner.factoryFor('service:i18n') : owner._lookupFactory('service:i18n');
      this.set('i18n', factory ? factory.create() : null);
    },

    _submitLabel: _ember['default'].computed('config', 'submit-label', function () {
      return this._getLabel('submit') || 'Save';
    }),

    _getLabel: function _getLabel(type) {
      var i18n = this.get('i18n');
      var label = this._config(type);
      return i18n ? i18n.t(label) : label;
    },

    _config: function _config(type) {
      return this.get('config.label.' + type);
    },

    formId: _ember['default'].computed('form-id', function () {
      var formId = this.get('form-id') ? this.get('form-id') : '';
      return formId;
    }),

    submitClass: _ember['default'].computed('config', function () {
      return this.get('config.css.submit') || this.get('config.css.button');
    }),

    actions: {
      submit: function submit() {
        var _this = this;

        this.set('submitted', true);
        var model = this.get('model');

        if (!model || !model.validate) {
          var task = this.get('on-submit');
          runTaskOrAction(task, model);
          return;
        }

        model.validate().then(function () {
          if (model.get('isInvalid')) {
            return;
          }
          var task = _this.get('on-submit');
          runTaskOrAction(task, model);
          _this.set('submitTask', task);
        });
      }
    }
  });
});