define('ember-query-params-reset/utils/reset-query-params', ['exports', 'ember-metal/get', 'ember-metal/set'], function (exports, _get, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (route, optionalArrayOfQueryParams) {
    var controller = (0, _get.default)(route, 'controller');
    var qps = getQueryParams(route);

    var queryParamsToReset = optionalArrayOfQueryParams;

    var defaultValues = {};

    for (var i in qps) {
      if (!Object.hasOwnProperty.call(qps, i)) {
        continue;
      }

      var qp = qps[i];
      if (!shouldResetQueryParam(queryParamsToReset, qp)) {
        continue;
      }

      var defaultValue = resetQueryParam(controller, qp);

      defaultValues[qp.prop] = defaultValue;
    }

    return defaultValues;
  };

  function getQueryParams(route) {
    return (0, _get.default)(route, '_qp.qps');
  }

  function shouldResetQueryParam(queryParamsToReset, qp) {
    return !queryParamsToReset || queryParamsToReset.indexOf(qp.prop) !== -1;
  }

  function resetQueryParam(controller, qp) {
    var defaultValue = void 0;

    if (qp.hasOwnProperty('def')) {
      // < v2.0
      defaultValue = qp.def;
    } else {
      // >= v2.0
      defaultValue = qp.defaultValue;
    }

    (0, _set.default)(controller, qp.prop, defaultValue);

    return defaultValue;
  }
});