define('ember-validated-form/components/validated-input', ['exports', 'ember', 'ember-validated-form/templates/components/validated-input'], function (exports, _ember, _emberValidatedFormTemplatesComponentsValidatedInput) {

  /**
   * This component wraps form inputs.
   *
   * It can be used in a two-way-binding style like
   * {{validated-input model=model name='firstName'}} (model will be updated)
   *
   * or in a one-way-binding style
   * {{validated-input model=model name='firstName' on-update=(action "update"}}
   * (update action is called, model is not updated)
   */
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberValidatedFormTemplatesComponentsValidatedInput['default'],

    dirty: false,

    required: false,

    type: 'input',

    classNameBindings: ['dirty', 'config.css.group', 'validationClass'],

    validationClass: _ember['default'].computed('showError', function () {
      var errorClass = this.get('config.css.error') || 'has-error';
      var validClass = this.get('config.css.valid') || 'valid';

      return this.get('showError') ? errorClass : validClass;
    }),

    error: _ember['default'].computed('model.error', function () {
      var error = this.get('model.error');
      return error ? error[this.get('name')] : null;
    }),

    isValid: _ember['default'].computed('error', function () {
      return !this.get('error');
    }),

    firstError: _ember['default'].computed('error', function () {
      return this.get('error.validation')[0];
    }),

    showError: _ember['default'].computed('isValid', 'dirty', 'submitted', function () {
      return !this.get('isValid') && (this.get('dirty') || this.get('submitted'));
    }),

    requiredLabel: _ember['default'].computed('config', function () {
      return this.get('config.label.required') || '*';
    }),

    actions: {
      setDirty: function setDirty() {
        this.set('dirty', true);
      },

      update: function update(value) {
        if (this.attrs['on-update']) {
          this.attrs['on-update'](value, this.get('model'));
        } else {
          this.set('model.' + this.get('name'), value);
        }
      }
    }
  });
});