define('ember-collapsible-panel/components/cp-panels/component', ['exports', 'ember-collapsible-panel/components/cp-panels/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: 'cp-Panels',
    accordion: false,
    animate: true,

    _cpPanels: true,

    name: Ember.computed.oneWay('elementId')
  });
});