define('ember-models-table/components/models-select', ['exports', 'ember-models-table/templates/components/models-select'], function (exports, _modelsSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    layout: _modelsSelect.default,

    tagName: 'select',

    classNameBindings: ['cssPropertyName'],

    cssPropertyName: '',

    change: function change() {
      this.set('value', this.$('option:selected').val());
    }
  });
});