define('ember-validated-form/components/validated-button', ['exports', 'ember', 'ember-validated-form/templates/components/validated-button'], function (exports, _ember, _emberValidatedFormTemplatesComponentsValidatedButton) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberValidatedFormTemplatesComponentsValidatedButton['default'],
    type: 'button',
    action: null,

    tagName: 'button',

    classNameBindings: ['class'],

    attributeBindings: ['disabled', 'type', 'action:onclick']
  });
});