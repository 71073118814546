define('ember-file-upload/computed/sum-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (collectionKey, itemKey) {
    return computed(collectionKey + '.@each.' + itemKey, function () {
      var collection = get(this, collectionKey);

      return collection.reduce(function (sum, item) {
        return sum + get(item, itemKey);
      }, 0);
    });
  };

  var get = Ember.get,
      computed = Ember.computed;
});