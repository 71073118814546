define('ember-pdf-js/components/pdf-js', ['exports', 'ember-pdf-js/templates/components/pdf-js'], function (exports, _pdfJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var _PDFJS = PDFJS,
      PDFFindController = _PDFJS.PDFFindController,
      PDFHistory = _PDFJS.PDFHistory,
      PDFLinkService = _PDFJS.PDFLinkService,
      PDFViewer = _PDFJS.PDFViewer;
  var A = Ember.A,
      Component = Ember.Component,
      computed = Ember.computed,
      reads = Ember.computed.reads,
      injectService = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      observer = Ember.observer,
      run = Ember.run;


  function scrollToMatch(pdfViewer, match) {
    var pageIdx = match.pageIdx,
        matchIdx = match.matchIdx;

    var page = pdfViewer.getPageView(pageIdx);
    var textLayer = page.textLayer;

    if (!textLayer) {
      // Ember.Logger.debug(`page ${pageIdx} not ready`)
      page.div.scrollIntoView();
      run.later(function () {
        // Ember.Logger.debug('re-running scrollToMatch')
        scrollToMatch(pdfViewer, match);
      }, 50);
    } else {
      // Ember.Logger.debug('ready to scroll right to the match')
      if (!textLayer.textContent) {
        // Ember.Logger.debug('textLayer.textContent ', textLayer.textContent)
        // Ember.Logger.debug('page->', page)
        run.later(function () {
          // Ember.Logger.debug('re-running scrollToMatch')
          scrollToMatch(pdfViewer, match);
        }, 50);
      } else {
        var _textLayer$convertMat = textLayer.convertMatches([matchIdx], [1]),
            _textLayer$convertMat2 = _slicedToArray(_textLayer$convertMat, 1),
            divIdx = _textLayer$convertMat2[0].begin.divIdx;

        textLayer.textDivs[divIdx].scrollIntoView();
        // debugger
      }
    }
  }

  function getResultContextForPage(pdfViewer, pageIdx, pageMatches, searchResultContextLength) {
    var pageTextPromise = pdfViewer.getPageTextContent(pageIdx).then(function (_ref) {
      var items = _ref.items;
      return items.map(function (item) {
        return item.str;
      }).join(' ');
    });
    /* this will cause some shifting:
     - we are adding extra space compare to what the findController is doing
     but it is required since some pdf don't add space at the end of their text divs
     and we would end up with some text missing spaces and "lookingLikethat"
     */
    return pageMatches.map(function (matchIdx, idx) {
      return {
        context: pageTextPromise.then(function (text) {
          var startPosition = matchIdx - searchResultContextLength * 0.5;
          startPosition = startPosition > 0 ? startPosition : 0;
          return text.substr(startPosition, searchResultContextLength);
        }),
        matchIdx: idx,
        pageIdx: pageIdx
      };
    });
  }

  exports.default = Component.extend({
    layout: _pdfJs.default,
    classNames: ['pdf-js'],
    // Service
    pdfJs: injectService('pdf-js'),
    pdfLib: reads('pdfJs.PDFJS'),

    // inputs
    pdf: undefined,

    // variables
    loadingTask: undefined,
    percentLoaded: 0,
    pdfDocument: undefined,
    pdfLinkService: undefined,
    pdfHistory: undefined,
    pdfViewer: undefined,
    pdfFindController: undefined,
    pdfPage: undefined,
    pdfTotalPages: undefined,
    currentMatch: undefined,
    currentMatchIdx: undefined,
    matchTotal: undefined,

    // privates
    _topMargin: 10,
    _searchResultContextLength: 100,
    _container: undefined,

    // components
    toolbarComponent: 'pdf-js-toolbar',

    // initialization
    didInsertElement: function didInsertElement() {
      var _this = this;

      var _element$getElementsB = this.element.getElementsByClassName('pdfViewerContainer'),
          _element$getElementsB2 = _slicedToArray(_element$getElementsB, 1),
          container = _element$getElementsB2[0];

      this.set('_container', container);
      var pdfLinkService = new PDFLinkService();
      this.set('pdfLinkService', pdfLinkService);
      var pdfViewer = new PDFViewer({
        container: container,
        linkService: pdfLinkService
      });
      this.set('pdfViewer', pdfViewer);
      pdfLinkService.setViewer(pdfViewer);
      var pdfHistory = new PDFHistory({
        linkService: pdfLinkService
      });
      this.set('pdfHistory', pdfHistory);
      pdfLinkService.setHistory(pdfHistory);
      var pdfFindController = new PDFFindController({
        pdfViewer: pdfViewer
      });
      this.set('pdfFindController', pdfFindController);
      // Ember.Logger.debug('pdfFindController -> ', pdfFindController)
      // Ember.Logger.debug('pdfViewer -> ', pdfViewer)
      pdfViewer.setFindController(pdfFindController);
      pdfViewer.currentScaleValue = 'page-fit';

      // setup the event listening to synchronise with pdf.js' modifications
      pdfViewer.eventBus.on('pagechange', function (evt) {
        var page = evt.pageNumber;
        run(function () {
          _this.set('pdfPage', page);
        });
      });

      pdfFindController.onUpdateResultsCount = function (total) {
        run(function () {
          _this.set('matchTotal', total);
        });
      };
      pdfFindController.onUpdateState = function (state /*, previous, total*/) {
        run(function () {
          if (state === 3) {
            _this.set('isSearchPending', true);
            return;
          }
          if (state !== 0 && state !== 2) {
            // 0 <=> search found something ; 2 <=> wrapped
            _this.set('currentMatch', undefined);
            _this.set('currentMatchIdx', undefined);
            _this.set('matchTotal', undefined);
            return;
          }
          _this.set('isSearchPending', false);
          var _pdfFindController$se = pdfFindController.selected,
              pageIdx = _pdfFindController$se.pageIdx,
              matchIdx = _pdfFindController$se.matchIdx;

          if (matchIdx !== -1 || pageIdx !== -1) {
            var pageMatches = pdfFindController.pageMatches;

            var idx = matchIdx + 1;
            for (var i = 0; i < pageIdx; i++) {
              idx += pageMatches[i].length;
            }
            var match = pdfFindController.pageMatches[pageIdx][matchIdx];
            _this.set('currentMatch', match);
            _this.set('currentMatchIdx', idx);
          }
        });
      };

      if (this.get('pdf')) {
        this.send('load');
      }
    },


    // observer
    pdfObserver: observer('pdf', function () {
      this.send('load');
    }),
    // we use an observer to setup this property to provide some sort of caching...
    searchResultsObserver: observer('isSearchPending', 'matchTotal', function () {
      var pdfFindController = this.get('pdfFindController');
      var pdfViewer = this.get('pdfViewer');
      var pageMatches = pdfFindController.pageMatches;

      if (this.get('isSearchPending')) {
        this.set('searchResultContextsPerPage', A());
        return;
      }
      // else
      var searchResultContextsPerPage = this.get('searchResultContextsPerPage');
      var length = searchResultContextsPerPage.get('length');
      if (length >= pageMatches.length) {
        // no new matches yet let's wait
        return;
      }
      var searchResultContextLength = this.get('_searchResultContextLength');
      // let currentMatchIdx = pageMatches.slice(0, length).reduce((totalMatches, formattedContext) => totalMatches + formattedContext.length, 0)
      for (var i = length; i < pageMatches.length; i++) {
        if (isEmpty(pageMatches[i])) {
          searchResultContextsPerPage.pushObject([]);
        } else {
          searchResultContextsPerPage.pushObject(getResultContextForPage(pdfViewer, i, pageMatches[i], searchResultContextLength));
        }
        // currentMatchIdx += pageMatches[i].length
      }
    }),

    // computed
    searchResultContexts: computed('searchResultContextsPerPage.[]', function () {
      var searchResultContextsPerPage = this.get('searchResultContextsPerPage');
      if (isEmpty(searchResultContextsPerPage)) {
        return [];
      } // else
      return searchResultContextsPerPage.reduce(function (joinedContexts, contextsOfPage) {
        return joinedContexts.concat(contextsOfPage);
      }, []);
    }),

    // actions:
    actions: {
      load: function load() {
        var _this2 = this;

        var uri = this.get('pdf');
        var loadingTask = this.get('pdfLib').getDocument(uri);
        loadingTask.onProgress = function (progressData) {
          _this2.set('percentLoaded', 100 * progressData.loaded / progressData.total);
        };

        loadingTask = loadingTask.then(function (pdfDocument) {
          _this2.set('pdfDocument', pdfDocument);
          var viewer = _this2.get('pdfViewer');
          viewer.setDocument(pdfDocument);
          var linkService = _this2.get('pdfLinkService');
          linkService.setDocument(pdfDocument);
          var history = _this2.get('pdfHistory');
          history.initialize(pdfDocument.fingerprint);
          _this2.set('pdfTotalPages', linkService.pagesCount);
          _this2.set('pdfPage', linkService.page);
          _this2.sendAction('documentChanged', pdfDocument);
        });

        this.set('loadingTask', loadingTask);
        return loadingTask;
      },
      search: function search(query, highlightAll, caseSensitive, phraseSearch) {
        var pdfFindController = this.get('pdfFindController');
        pdfFindController.executeCommand('find', {
          query: query,
          highlightAll: highlightAll,
          caseSensitive: caseSensitive,
          phraseSearch: phraseSearch
        });
      },
      goToMatch: function goToMatch(matchIdx, pageIdx) {
        var pdfFindController = this.get('pdfFindController');
        // looks like a stupid hack but I haven't found better...
        // place the offset "one match before"
        pdfFindController.offset = {
          pageIdx: pageIdx,
          matchIdx: matchIdx - 1
        };
        console.log('new offset -> ', pdfFindController.offset);
        // then call changeSearchResult with next ...
        this.send('changeSearchResult', 'next');
      },
      changeSearchResult: function changeSearchResult(changeDirection) {
        var pdfFindController = this.get('pdfFindController');
        if (!pdfFindController.state) {
          return; // there is no search going on so let's ignore that call
        }
        switch (changeDirection) {
          case 'prev':
            pdfFindController.state.findPrevious = true;
            pdfFindController.nextMatch();
            break;
          case 'next':
            pdfFindController.state.findPrevious = false;
            pdfFindController.nextMatch();
            break;
          default:
            return;
        }
        scrollToMatch(this.get('pdfViewer'), pdfFindController.selected);
      },
      changePage: function changePage(_changePage) {
        var pdfLinkService = this.get('pdfLinkService');
        switch (_changePage) {
          case 'prev':
            pdfLinkService.page--;
            break;
          case 'next':
            pdfLinkService.page++;
            break;
          default:
            // regular change of page:
            pdfLinkService.page = Number.parseInt(_changePage);
        }
        var pdfViewer = this.get('pdfViewer');
        pdfViewer.getPageView(pdfLinkService.page - 1).div.scrollIntoView();
      },
      zoom: function zoom() {
        throw new Error('not implemented yet');
      }
    }
  });
});