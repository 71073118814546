define('ember-sortable/system/scroll-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var ScrollContainer = function () {
    function ScrollContainer(element) {
      _classCallCheck(this, ScrollContainer);

      this.element = element;
      this.isWindow = element === document;
      if (this.isWindow) {
        this.top = this.scrollTop();
        this.left = this.scrollLeft();
        this.width = Ember.$(window).width();
        this.height = Ember.$(window).height();
        this.scrollWidth = this.$().width();
        this.scrollHeight = this.$().height();
      } else {
        var _$$offset = this.$().offset(),
            top = _$$offset.top,
            left = _$$offset.left;

        this.top = top;
        this.left = left;
        this.width = this.$().width();
        this.height = this.$().height();
        this.scrollWidth = element.scrollWidth;
        this.scrollHeight = element.scrollHeight;
      }
      this.maxScrollTop = this.scrollHeight - this.height;
      this.maxScrollLeft = this.scrollWidth - this.width;
    }

    _createClass(ScrollContainer, [{
      key: 'scrollTop',
      value: function scrollTop(value) {
        if (value) {
          value = Math.max(0, Math.min(this.maxScrollTop, value));
          this.$().scrollTop(value);
          if (this.isWindow) {
            this.top = value;
          }
          return value;
        }
        return this.$().scrollTop();
      }
    }, {
      key: 'scrollLeft',
      value: function scrollLeft(value) {
        if (value) {
          value = Math.max(0, Math.min(this.maxScrollLeft, value));
          this.$().scrollLeft(value);
          if (this.isWindow) {
            this.left = value;
          }
          return value;
        }
        return this.$().scrollLeft();
      }
    }, {
      key: '$',
      value: function $(selector) {
        var element = this.element;
        if (selector) {
          return Ember.$(element).find(selector);
        } else {
          return Ember.$(element);
        }
      }
    }, {
      key: 'bottom',
      get: function get() {
        return this.top + this.height;
      }
    }, {
      key: 'right',
      get: function get() {
        return this.left + this.width;
      }
    }]);

    return ScrollContainer;
  }();

  exports.default = ScrollContainer;
});