define("ember-inputmask/utils/compare-objects", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.areDifferent = areDifferent;
  function areDifferent(obj1, obj2) {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  }
});