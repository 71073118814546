define('ember-collapsible-panel/services/panel-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var State = Ember.Object.extend({
    name: null,
    boundOpenState: false,
    apiOpenState: false,
    apiWasUsed: false,

    isOpen: Ember.computed('boundOpenState', 'apiOpenState', 'apiWasUsed', function () {
      if (this.get('apiWasUsed')) {
        return this.get('apiOpenState');
      } else {
        return this.get('boundOpenState');
      }
    }),

    animate: true,
    group: null
  });

  exports.default = Ember.Service.extend({
    _registry: Ember.Object.create({
      keys: Ember.A([]),

      unknownProperty: function unknownProperty(name) {
        var state = State.create();

        this.get('keys').addObject(name);
        this.set(name, state);

        return state;
      },

      // probably not too safe, should only be used in tests
      reset: function reset() {
        var _this = this;

        this.get('keys').map(function (i) {
          return i;
        }) // copy, so we dont mess with binding/loops
        .forEach(function (key) {
          delete _this[key];
        });

        this.get('keys').clear();
      }
    }),

    state: Ember.computed.readOnly('_registry'),

    _panelFor: function _panelFor(name) {
      return this.get('state.' + name);
    },


    _panels: Ember.computed('state.keys.[]', function () {
      var keys = this.get('state.keys'),
          state = this.get('state');

      return keys.reduce(function (result, key) {
        return result.addObject(state.get(key));
      }, Ember.A([]));
    }),

    _panelsInGroup: function _panelsInGroup(name) {
      return this.get('_panels').filterBy('group.name', name);
    },
    open: function open(name) {
      var panel = this._panelFor(name),
          group = panel.get('group');

      if (group && group.get('accordion')) {
        // if this is part of an accordion close
        // everything else
        this.closeAll(group.get('name'));
      }

      panel.set('apiOpenState', true);
      panel.set('apiWasUsed', true);
    },
    close: function close(name) {
      this._panelFor(name).set('apiOpenState', false);
      this._panelFor(name).set('apiWasUsed', true);
    },
    toggle: function toggle(name) {
      var panel = this._panelFor(name);
      return panel.get('isOpen') ? this.close(name) : this.open(name);
    },
    openAll: function openAll(group) {
      this._panelsInGroup(group).forEach(function (panel) {
        panel.set('apiOpenState', true);
        panel.set('apiWasUsed', true);
      });
    },
    closeAll: function closeAll(group) {
      this._panelsInGroup(group).forEach(function (panel) {
        panel.set('apiOpenState', false);
        panel.set('apiWasUsed', true);
      });
    }
  });
});